import get from '@/api/base/with-authorization/get';
/**
 * Fetch a list of space features.
 *
 * @param {number} id Space ID.
 *
 * @returns {Promise}
 */
export default function (id) {
    return get(`space/${id}/feature/`);
}
